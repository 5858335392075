body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-item{
  cursor: pointer;
}

.pressable tr{
  border-width: 0px;
  cursor: pointer;
}
.pressable tr :hover{
  border-width: 1px;
  cursor: pointer;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.delete-icon {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  background-color: red;
  background-image: url('./assets/icons/trash.svg');
  background-origin: content-box;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  border-radius: 10%;
}

/* Estilo para a imagem */
.image-container {
  position: relative;
  max-width: 200px;
  max-height: 200px;
}

/* Estilo para limitar o tamanho da imagem */
.image-container img {
  max-width: 100%;
  height: auto;
}

/* Exibir o ícone de excluir quando o mouse estiver sobre a imagem */
.image-container:hover .delete-icon {
  display: block;
}